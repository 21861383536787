<template>
  <div class="mt-4 flex-column">
    <div class="d-flex justify-content-center">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2>
    </div>
    <div class="misc-inner p-2 p-sm-3 text-center">
      <b-row>
        <b-col>
          <b-card
            class="px-4 py-2"
            style="height: 45vh"
          >
            <feather-icon
              icon="BoxIcon"
              size="80"
              class="text-warning"
            />
            <h1 class="my-2">
              Sandbox
            </h1>
            <h4 class="text-secondary mb-2">
              Environment สำหรับทดสอบเชื่อมต่อเข้ากับระบบเกม
            </h4>
            <br>
            <b-button
              variant="primary"
              @click="onSelectEnvironment(0)"
            >
              เข้าใช้งาน
            </b-button>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            class="px-4 py-2"
            style="height: 45vh"
          >
            <feather-icon
              icon="MonitorIcon"
              size="80"
              class="text-secondary"
            />
            <h1 class="my-2">
              Production
            </h1>
            <h4 class="text-secondary mb-2">
              Environment สำหรับเชื่อมต่อเพื่อขึ้นระบบจริง
            </h4>
            <b-card-text class="text-danger">
              ยังไม่ได้รับสิทธิ์เข้าใช้งานระบบ Production
            </b-card-text>
            <b-button
              variant="primary"
              @click="onSelectEnvironment(1)"
            >
              เข้าใช้งาน
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
  <!-- / Under maintenance-->
</template>

<script>
import { mapActions } from 'vuex'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    VuexyLogo,
  },
  data() {
    return {}
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  computed: {},
  methods: {
    ...mapActions(['setEnvironment']),
    onSelectEnvironment(env) {
      this.setEnvironment(env)
      this.$router.push('/request')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
